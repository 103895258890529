import { Link } from "react-router-dom";
import AboutImg from "../assets/images/about-care.png";
import Elipses from "../assets/images/elipses.png";
import Mission from "../assets/images/mission-1.png";

export const About = () => {
    return (
        <>
            <div className="bg-[#FAF0FF] mt-20 py-10" id="about">
                <div className="mx-auto w-full max-w-screen-xl p-4 md:py-10 flex flex-col-reverse  md:flex-col lg:flex-row items-start justify-between flex-shrink-0 text-[#292D32] h-full">
                    <div className="lg:w-1/2">

                        <p className="md:my-12 text-md lg:w-[80%] text-[#292D32] text-center font-[500] lg:text-left">
                            At Angel Of Royalty Home Care LLC, We provide affordable housing to a variety of individuals with diverse needs. Demographics we serve are but not limited to Veterans, Re-entry, Seniors, Disabled, Mental Health, Sober living, Low-Income, and other diverse populations. We provide all-inclusive short term and permanent placement.    </p>

                        <div className="mt-5 md:mt-0 flex items-center justify-center lg:justify-start gap-5">
                            <a className="bg-[#B931F9] rounded-[16px] px-8 py-3.5 text-white text-sm font-[500]" href="mailto:info@aofthomecare.com">Book Appointment</a>

                        </div>

                    </div>

                    <div className="w-full md:w-1/2  relative">

                        <div className="md:py-10">
                            <h2 className="text-2xl md:text-3xl font-[700] text-[#262626] text-center lg:text-left mb-3">
                                About Angel

                            </h2>
                            <h2 className="text-2xl md:text-3xl font-[700] text-[#262626] text-center lg:text-left">
                                <span className="text-[#B931F9]">Royalty Home Care</span>

                            </h2>

                            <img src={AboutImg} alt="Hero" className="lg:w-[80%] relative my-10 md:my-0  ml-auto md:absolute md:top-[120%]" />
                        </div>
                    </div>

                    <div>

                    </div>
                </div>
            </div>
            <div id="services" className="mx-auto w-full max-w-screen-xl p-4 md:py-10 flex flex-col lg:flex-row items-start justify-between flex-shrink-0 text-[#292D32] h-full">
                <div className="w-full py-5 md:py-10  md:py-0 lg:w-1/2">

                    <h2 className="text-2xl md:text-3xl font-[700] text-[#262626] text-center lg:text-left mb-1">
                        <span className="text-[#B931F9]">Services</span> We

                    </h2>
                    <h2 className="text-2xl md:text-3xl font-[700] text-[#262626] text-center lg:text-left">
                        Provide

                    </h2>
                </div>
            </div>
            <div className="mx-auto w-full max-w-screen-xl p-4 md:py-10 flex  text-[#292D32] h-full grid grid-cols-1 md:grid-cols-3 gap-10">
                <div>
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">Private and Shared Rooms</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Need a private or shared room for yourself or loved one? We have got you covered.</p>
                </div>
                <div>
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">Veterans</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">We have 24/7 wi-fi services for you to browse the internet.</p>
                </div>
                <div>
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">Mental Health Assistance</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">We have 24/7 surveillance making our  home safe and secure.</p>
                </div>
                <div>
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">Re-Entry</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Our house manager stays in the home to endure rules are observed.</p>
                </div>
                <div>
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">Individuals in Recovery</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Well furnished and equipped recreation room to keep you busy.</p>
                </div>
                <div>
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">General Population</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Our  home is 100% furnished to suite everyone.</p>
                </div>


            </div>
            <section className="bg-[#FAF0FF]">
                <div className="mx-auto w-full max-w-screen-xl p-4 md:py-10 flex  text-[#292D32] h-full grid grid-cols-1 md:grid-cols-2 md:gap-10">
                    <div>
                        <img src={Mission} alt="elipses" className="w-[100%] h-[100%] object-fit-cover" />

                    </div>

                    <div>
                        <h2 className="text-2xl md:text-3xl font-[700] text-[#262626] text-center lg:text-left mb-1">
                            Our Mission

                        </h2>
                        <h2 className="text-2xl md:text-3xl font-[700] text-[#262626] text-center lg:text-left">
                            & <span className="text-[#B931F9]">Vision</span>

                        </h2>

                       <div className="md:w-[70%] my-12 ">
                       <div className="bg-white px-5 py-4 rounded-xl">
                            <h5 className="text-md font-semibold mt-2">Our Mission</h5>
                            <p className="my-2 text-sm lg:w-1080%] text-[#292D32]  font-[500]">In the Lutheran tradition of caring through service, Compass Housing Alliance develops and provides essential services and affordable housing for homeless and low-income people in the greater Puget Sound region.</p>
                        </div>
                       <div className="bg-white px-5 py-4 rounded-xl mt-12">
                            <h5 className="text-md font-semibold mt-2">Our Vision</h5>
                            <p className="my-2 pb-4 text-sm lg:w-1080%] text-[#292D32]  font-[500]">We envision a world in which every person lives in a safe, caring community.</p>
                        </div>
                       </div>
                    </div>




                </div>
            </section>
        </>
    )
}